


import {Slider} from '../index'

const Topbox = () => {
    return (
        <>
            <section>


                <div className='columns padtopmobile navpadend150 navpadstart150'>

                    <div className='column is-12'>
                        <Slider/>

                    </div>



                </div>
            </section>

        </>
    )
}

export default Topbox;