import {useEffect,useState} from 'react'
import { getSplash} from '../services/services'
import {Accardion} from '../components'
import {LinearProgress} from "@mui/material";

const Faq = () => {
    const [dataNeeded,setData] = useState(false)

    const getdata = async ()=>{
        const res = await getSplash()
        if (res){
            const {faq} = res.data
            setData(faq)
        }
    }

    useEffect(() => {
        getdata().then();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });



    }, []);

    let content
    if (dataNeeded!==false){

        content = dataNeeded.map((d)=>(
            <Accardion key={d.id} a={d.answer} q={d.q} id={d.id}/>
        ))

    } else {
        content =  <div className='column is-12'>
            <LinearProgress variant='indeterminate' color='secondary'  sx={{
                '&.MuiLinearProgress-root':{
                    backgroundColor:'#00A651'
                }


            }}/>
        </div>
    }



  return(
      <>
          <main>
              <section>

                  <div className='columns navpadstart150 navpadend150 mx-0 is-multiline padtopmobile'>

                      <div className='column is-12 is-relative' >
                          <img className='title-img' src={'/icons/Ellipse 3.png'} width={40} alt="icon" style={{}}/>

                          <h1 className='is-size-4-mobile is-size-4-desktop kook has-text-weight-bold mb-6'>
                              سوالات متداول
                          </h1>



                      </div>

                      {content}

                  </div>
              </section>
          </main>


      </>
  )
}
export default Faq;