import {LinearProgress, Tooltip} from '@mui/material'


import {Link} from 'react-router-dom'
import React, {useEffect, useState} from "react";
import {getSplash} from "../services/services";



const Footer = () => {
    const [data, setData] = useState(false)

    const getData = async () => {
        const response = await getSplash()
        if (response) {
            setData(response.data.info)
        } else {
           alert('مشکل در ارتباط با سرور')
        }
    }


    useEffect(() => {

        getData()
    }, []);


    let content
    if (data !== false) {

        content = <p className="mt-3 has-text-justified has-text-weight-bold">
            {data.safiran_about}
        </p>
    } else {
        content = <div className='column is-12'>
            <LinearProgress variant='indeterminate' color='secondary' sx={{
                '&.MuiLinearProgress-root': {
                    backgroundColor: '#00A651'
                }

            }}/>
        </div>
    }


    return (<>
        <footer>
            <div className="columns navpadstart150 navpadend150  mx-0 px-2 container mt-6">
                <div className="column  is-5-desktop">
                    <div className="column  is-8-desktop">
                            <span className="is-flex is-align-items-center">
                             <img
                                 src="/icons/headlogo.png"
                                 alt="logo"
                                 width="50"
                                 height="50"
                             />
                                           <h4 className="mr-2">سفیران نوآوری و اندیشه افق ایرانیان</h4>
                          </span>

                        {
                            content
                        }


                        <a
                            target="_blank"
                            href="https://trustseal.enamad.ir/?id=485246&Code=fgZvdEKfQ0hTBsVj1FV5uGuajKPJ03lX"
                        >
                            <img
                                referrerPolicy="origin"
                                src="https://trustseal.enamad.ir/Content/Images/Star2/81.png?v=5.0.0.3777"
                                alt=""
                                style={{cursor: "pointer", borderRadius: '0.3rem'}}
                                code="fgZvdEKfQ0hTBsVj1FV5uGuajKPJ03lX"
                            />
                        </a>

                    </div>

                </div>
                <div className="column  mt-5 px-5">
                    <h4>ارتباط با ما</h4>
                    <ul className="mt-4 footer__contact">
                        <li className="footer__phone">شماره تماس</li>
                        <li>

                            {
                                data !== false ? <a style={{color: 'inherit'}}
                                                    href={`tel:${data.safiran_phone}`}>{data.safiran_phone}</a> : "..."
                            }
                        </li>

                    </ul>
                    <ul className="mt-4 footer__contact">
                        <li className="footer__gmail">ایمیل</li>
                        <li>

                            {
                                data !== false ? <a style={{color: 'inherit'}}
                                                    href={`mailto:${data.safiran_email}`}>{data.safiran_email}</a> : "..."
                            }
                        </li>
                    </ul>
                    <ul className="mt-4 footer__contact">
                        <li className="footer__loc">آدرس</li>
                        <li>

                            {
                                data !== false ? <a target={"_blank"} style={{color: 'inherit'}}
                                                    href={`https://www.google.com/maps/@${data.safiran_lat},${data.safiran_lng},15z?entry=ttu}`}>{data.safiran_address} </a> : "..."
                            }
                        </li>
                    </ul>
                </div>
                <div className="column  mt-5 px-5">
                    <h4>لینک های سریع</h4>
                    <ul className="mt-4">
                        <Link to={'/blog/'}>
                            <li>بلاگ</li>
                        </Link>

                        <Link to={'/courses/'}>

                            <li>دوره ها</li>
                        </Link>



                    </ul>
                </div>
                <div className="column  mt-5 px-5">
                    <h4>راهنمایی و خدمات</h4>
                    <ul className="mt-4">
                        {/*nofollow*/}

                        <li>
                            <a style={{color: 'inherit'}} href="https://bilitim.net/rules" target={'_blank'}
                               rel={'nofollow'}>
                                قوانین و مقررات
                            </a>
                        </li>
                        <li>
                            <a style={{color: 'inherit'}} href="https://bilitim.net/inquiry/" target={'_blank'}
                               rel={'nofollow'}>
                                پیگیری سفارش
                            </a>
                        </li>
                    </ul>
                </div>


            </div>
            <div className='column is-12-mobile'>
                <div className="is-flex socials_foot">
              <span
                  className="is-flex is-align-items-center is-justify-content-center mx-1"
              >
                  <a href={data.safiran_whatsapp}>
                      <img src="/icons/whatsapp.png" alt=""/>
                  </a>


              </span>
                    <span
                        className="is-flex is-align-items-center is-justify-content-center mx-1"
                    >
                        <a href={data.safiran_telegram}>
                      <img src="/icons/telegram.png" alt=""/>
                  </a>

              </span>

                    <span
                        className="is-flex is-align-items-center is-justify-content-center mx-1"
                    >
                              <a href={data.safiran_email}>
                        <img src="/icons/gmail.png" alt=""/>
                  </a>

              </span>
                    <span
                        className="is-flex is-align-items-center is-justify-content-center mx-1"
                    >
                        <a href={data.safiran_instagram}>
                      <img src="/icons/instagram.png" alt=""/>
                  </a>

              </span>
                </div>
            </div>
            <div className="">
                <hr/>
                <div className="columns mx-0 ">
                    <div className="column is-flex is-align-items-center">
                        <p>
                            کپی رایت 2024 – تمام حقوق برای سازمان موسسه سفیران نوآوری و اندیشه
                            افق ایرانیان محفوظ است


                            < Tooltip title='
              < Coded By : Ramin Hasani />
              '
                            >
                                .
                            </Tooltip>
                        </p>
                    </div>

                </div>
            </div>
        </footer>

    </>)
}

export default Footer;