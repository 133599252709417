
import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'


const Blogcard = ({image,id,time,title,text}) => {

    let txt= function stripTags(text) {
        const stripped= text.replace(/<[^>]+>/g, '');
        if (stripped.length <= 200) {
            return stripped;
        }
        return text.substr(0, 200 ) + '...';
    }






    return (
        <>

            <motion.div className='column is-12-mobile is-6-tablet  is-6-desktop' variants={{
                hidden: {scale: 0},
                visible: {scale: 1, opacity: 1}
            }}>
                <div
                    className='card py-5 mx-auto is-flex is-flex-direction-row is-flex-wrap-wrap   is-align-content-start is-justify-content-end borderrad1  backgroundadj  '
                    style={{height: '31rem', backgroundImage: `url(${image})`}}>

                    <span className='clrone has-text-white borderrad1   kook is-size-5 ml-5 p-1 my-auto'
                          style={{float: 'left'}}>
                                 {time}
                              </span>





                </div>
                <div
                    className='content p-4  mr-1 is-align-self-flex-end cardbackground  mx-auto   '
                >


                    <p className='kook is-size-4 mb-1 '>
                        {
                            title
                        }





                    </p>
                    <article style={{fontSize:'0.7rem'}} dangerouslySetInnerHTML={{ __html: txt(text) }}></article>







                    <Link to={`/blogs/${id}`}>
                        <button className='button kook clronetext has-text-weight-bold is-size-5'>
                            مطالعه بیشتر
                        </button>
                    </Link>





                </div>

            </motion.div>


        </>
    )
}

export default Blogcard;