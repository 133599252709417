


import {LinearProgress} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getSplash} from "../../services/services";



const ContactBoxTwo = () => {
    const [data,setData] = useState(false)

    const getdata = async ()=>{
        const res = await getSplash()
        if (res){

            setData(res.data.info)
        }
    }

    useEffect(() => {
        getdata().then()



    }, []);



    return (
        <>
            <div className="contact__info shadowtwo p-6 mt-3" >
                <div className="columns p-6">
                    <div className="column is-12-mobile is-4-desktop my-6">
                        <span className="is-flex is-align-items-center is-justify-content-center">
                            <span className="info" style={{backgroundColor: '#00A651'}}>
                                <img src="/icons/icon_call_12.png" />
                            </span>
                            <h3 className="mr-3">شماره تماس</h3>
                        </span>
                        <div className="contact__number my-2 has-text-centered">

                            {
                                data !== false ? <a style={{color: 'inherit'}}
                                                    href={`tel:${data.safiran_phone}`}>{data.safiran_phone}</a> : "..."
                            }

                        </div>
                    </div>
                    <div className="column is-12-mobile is-4-desktop my-6">
                    <span className="is-flex is-align-items-center is-justify-content-center">
                        <span className="info " style={{backgroundColor: '#00A651'}}>
                                <img src="/icons/icon_location_12.png" alt='location' />
                            </span>
                            <h3 className="mr-3">آدرس</h3>
                        </span>
                        <div className="contact__number my-2">
                              {
                                  data !== false ? <a target={'_blank'} style={{color: 'inherit'}}
                                                      href={`https://www.google.com/maps/@${data.safiran_lat},${data.safiran_lng},15z?entry=ttu}`}>{data.safiran_address} </a> : "..."
                              }
                        </div>
                    </div>
                    <div className="column is-12-mobile is-4-desktop my-6">
                    <span className="is-flex is-align-items-center is-justify-content-center">
                        <span className="info " style={{backgroundColor: '#00A651'}}>
                                <img src="/icons/Vectorso.png" />
                            </span>
                            <h3 className="mr-3">شبکه های اجتماعی</h3>
                        </span>
                        <span className="contact__socials mt-5">
                            <a href={data.safiran_aparat}><img src="/icons/icon_aparat_20.png" width={35}/></a>
                            <a href={data.safiran_telegram}><img src="/icons/icon_telegram20.png" width={35}/></a>
                            <a href={data.safiran_instagram}><img src="/icons/icon_instagram_20.png" width={35}/></a>
                            <a href={data.safiran_whatsapp}><img src="/icons/icon_whatsapp_20.png" width={35}/></a>
                        </span>
                    </div>
                </div>
            </div>



      </>


  )
}
export default ContactBoxTwo;