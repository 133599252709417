import {useParams} from "react-router-dom";
import {getBlog,baseurl} from "../services/services";
import React, {useEffect, useState} from "react";
import {LinearProgress} from "@mui/material";


const SingleBlog = () => {
    let {blogID} = useParams()

    const [data, setData]=useState(false)

    const getdata = async ()=>{
        const options = {


            params: {id: blogID}
        };
        const res = await getBlog(options)
        if (res){

            setData(res.data)
        }
    }

    useEffect(() => {
        getdata().then()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    let content
    if (data!==false){
        content =
            <div className='columns p-3 is-multiline my-4 wdith100  shadowtwo padtopmobile'>
        <div className='column is-10'>
            <h1 className='is-size-4-desktop'>
                {data.blog.title}
            </h1>
        </div>

            <div className='column is-2'>
                <span className='clrone has-text-white borderrad1   kook is-size-6 ml-5 p-1 my-auto'
                      style={{float: 'left'}}>
                                 {data.blog.date_text}
                              </span>
            </div>


            <div className='column is-12 has-text-centered'>
                <img src={`${baseurl}/${data.blog.img}`} alt=""/>


            </div>

            <div className='column is-12'>
                <article dangerouslySetInnerHTML={{__html:data.blog.txt}}></article>
            </div>

        </div>

    } else {
        content = <div className='column is-12'>
            <LinearProgress variant='indeterminate' color='secondary' sx={{
                '&.MuiLinearProgress-root':{
                    backgroundColor:'#00A651'
                }

            }}/>
        </div>
    }


  return(
      <>
          <main>
              <div className='columns navpadend150 navpadstart150 mx-0 is-multiline my-4 '>


                  {
                      content
                  }

              </div>
          </main>




      </>
  )
}
export default SingleBlog;