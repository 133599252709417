import { motion } from 'framer-motion'
import {MidBox,TopBox,BottomBox} from '../components'
import {useEffect} from "react";


const Landing = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);


    return (
        <>
            <motion.main animate={{opacity: 1, y:0}} initial={{opacity: 0, y: [100,0]}} exit={{opacity: 0}} transition={{duration: .6}}>


                <TopBox/>
                <MidBox/>
                <BottomBox/>
            </motion.main>


        </>
    )
}
export default Landing;