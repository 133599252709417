import {Nav, Footer} from '../components'


import {Outlet} from 'react-router-dom'

import React from "react";




const Mainlayout = ({children}) => {







    return (
        <>
            <div className='is-flex is-flex-direction-column is-align-items-center ' style={{width: '100vw'}}>


                <div className='containerwidth '>
                    <Nav/>
                    <Outlet/>


                    {children}


                </div>

                <Footer/>

            </div>

        </>
    )
}
export default Mainlayout