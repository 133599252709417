import React from 'react';
import ReactDOM from 'react-dom/client';


import Mainlayout from "./Layouts/Mainlayout";
import reportWebVitals from './reportWebVitals';
import {

    RouterProvider
} from "react-router-dom";
import {router} from './routes/routes'








const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(



        <RouterProvider router={router}>


            <Mainlayout>

            </Mainlayout>



        </RouterProvider>

);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
