import {ContactBoxOne, ContactBoxTwo, ContactMap} from '../components'
import {useEffect} from "react";


const Contact = () => {


    useEffect(() => {
        // scroll to top when pagae is loaded
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);


    return (
        <>
            <main>

                <div className='container'>
                    <div className=' columns is-multiline mx-0 padtopmobile p-3'>


                        <div className='column is-12'>


                            <h1 className='is-size-4-mobile has-text-weight-bold'>
                                تماس با ما


                            </h1>

                            <p className=' my-3'>

                                جهت ارتباط با مجموعه ما میتوانید از راه های ارتباطی زیر استفاده کنید.

                            </p>


                        </div>


                        <ContactBoxTwo/>
                        <ContactBoxOne/>


                        <ContactMap/>


                    </div>
                </div>


            </main>


        </>

    )
}
export default Contact;