import {motion} from 'framer-motion'
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

import { Navigation, Pagination, Autoplay} from 'swiper/modules';

import {Link} from 'react-router-dom'
import {useEffect , useState} from 'react'
import {getSplash,baseurl} from '../../../services/services'

import {LinearProgress} from "@mui/material";

const TopSlider = () => {

    const [data,setData] = useState(false)

    const getData = async () => {
      const response = await getSplash()
        if (response) {
            setData(response.data)
        } else {
           alert('مشکل در ارتباط با سرور')
        }
    }


    useEffect(() => {

        getData()
    }, []);


    let content
    if (data !==false){
        content = data.safiran_slider.map((item,index)=>(
            <SwiperSlide key={index} style={{height:'100%'}}>

                <div className=' is-relative' style={{
                    backgroundImage:`url(${baseurl}/${item.img})`,
                    backgroundSize:"contain",
                    backgroundRepeat:'no-repeat',
                    backgroundPosition:'center',
                    height:'100%',




                }}>

                    <div className='kook has-text-white  bgblur shadowtwo slidercard p-3  '>


                        <p className='kook is-size-1-desktop is-size-6-mobile has-text-white '>

                            {item.title}

                        </p>










                    </div>



                </div>





            </SwiperSlide>
        ))
    } else {
        content = <div className='column is-12'>
            <LinearProgress variant='indeterminate' color='info'/>
        </div>
    }




    return (
        <>

            <div className='sliderheight'>
                <Swiper
                    spaceBetween={30}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}

                    speed={1618}
                    loop={true}
                    navigation={true}
                    pagination={{
                        enabled:true,
                        bulletActiveClass:'bulletbg',




                    }}




                    modules={[ Navigation, Pagination, Autoplay]}
                    style={{height:'100%'}}

                >
                    <SwiperSlide style={{height:'100%'}}>

                        <div className=' is-relative' style={{
                            backgroundImage:`url('images/standard-slider.png')`,
                            backgroundSize:"contain",
                            backgroundRepeat:'no-repeat',
                            backgroundPosition:'center',
                            height:'100%',




                        }}>

                            <motion.div animate={{ y: [-200,0] }} transition={{ duration: 1.2 , easings:'easeInOut' }} className='kook has-text-white  bgblur shadowtwo slidercard p-3  '>


                                <h1 className='kook is-size-1-desktop is-size-6-mobile '>

                                    {
                                        data !==false ? data.info.safiran_h1:"..."
                                    }

                                </h1>





                                <Link to='/courses' className='is-hidden-touch'>
                                    <button className='button is-size-5 kook has-text-weight-bold is-block borderrad1 mr-auto' style={{marginLeft:"0px"}}>
                                        مشاهده همایش ها
                                    </button>
                                </Link>




                            </motion.div>



                        </div>





                    </SwiperSlide>


                    {
                        content
                    }



                </Swiper>

            </div>




        </>
    )
}
export default TopSlider;