
import {EventCard} from "../components";
import {baseurl, url} from "../services/services";
import {Breadcrumbs, LinearProgress} from "@mui/material";
import {useEffect, useState} from "react";
import axios from "axios";
import {NavigateBefore, NavigateNext} from "@mui/icons-material";



const Courses = () => {

    const [data, setData] = useState(false)
    // search param for input
    const [search, setSearch] = useState('')

    const getdata = async (page) => {


        const options = {


            params: {page: page, take: 8, type: 2, search: search}
        };


        const response = await axios.get(`${url}/concerts`, options)
        if (response.data.code === 1) {
            setData(response.data)


        } else {
            alert(response.data.error)
        }

    }

    useEffect(() => {
        getdata().then(()=>
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        )


    }, []);


    useEffect(() => {
        getdata().then(       )


    }, [search]);


    let content
    if (data !== false) {


        content = data.items.map((item, index) => (


            <EventCard key={index} image={`${baseurl}/${item.concerts.img}`} id={item.concerts.id}
                       singer={item.concerts.title}
                       time={item.concerts.date_text}
                       location={item.locations_view.name} statusCode={item.concerts.active}
                       ts={item.concerts.date} remain={item.concerts.remain} city={item.cities.name}/>

        ))

    } else {
        content = <div className='column is-12'>
            <LinearProgress variant='indeterminate' color='secondary' sx={{
                '&.MuiLinearProgress-root':{
                    backgroundColor:'#00A651'
                }

            }}/>
        </div>
    }




    return (
        <>
            <main>
                <section>
                    <div className='columns  is-multiline mx-0 navpadend150 navpadstart150 padtopmobile'>
                        <div className='column is-12 hemayesh__top__lit'>
                            <img src={'/icons/Ellipse 3.png'} width={40} alt="icon" style={{}}/>

                            <h1 className='is-size-4-mobile is-size-6-desktop kook has-text-weight-bold mb-2 mt-1 mr-3'>
                                همایش ها
                            </h1>

                            <h2 className='is-size-4-mobile is-size-4-desktop kook has-text-weight-bold mb-5'>

                                دوره ها و همایش های موسسه
                            </h2>

                            <Breadcrumbs className='kook' aria-label="breadcrumb">
                      <span className='clrfivetext'>
                          صفحه اصلی
                      </span>

                                <span className='kook'>همایش ها</span>
                            </Breadcrumbs>
                        </div>

                        <div className='column is-12'>


                            <div className="field has-addons   ">
                                <div className="control wdith100">
                                    <input className="input kook"
                                           onChange={(event) => setTimeout(() => setSearch(event.target.value), 1600)}
                                           type="text" placeholder="نام سالن، خواننده، شهر یا کنسرت"/>
                                </div>
                                <div className="control">
                                    <a className="button borderrad1  has-text-weight-bold  " disabled>
                                        یافت شده :





                                        {data && data.paginator.total}




                                    </a>
                                </div>
                            </div>
                        </div>


                        <div className='column is-12'>
                            <div className='columns is-multiline my-4 '>
                                {content}
                            </div>
                        </div>


                        {data &&
                            <div className='morecard wdith100'>


                                {/*before page with tick*/}
                                {
                                    data.paginator.beforePage < data.paginator.currentPage && (

                                        <button onClick={() => getdata(data.paginator.beforePage)} className='button'>
                                            <NavigateNext/>
                                        </button>
                                    )


                                }


                                {/*map beforePages*/}
                                {data.paginator.beforePages.length > 0 &&

                                    <>
                                        {data.paginator.beforePages.map((item, index) =>
                                            <button onClick={() => getdata(item)} key={index}>
                                                {item}
                                            </button>
                                        )}
                                    </>

                                }


                                {/*active*/}

                                {
                                    data.paginator.total > 0 &&
                                    <button className='clrtwo has-text-white'>{data.paginator.currentPage}</button>


                                }


                                {/*map nextPages*/}
                                {data.paginator.nextPages.length > 0 &&
                                    <>
                                        {data.paginator.nextPages.map((item, index) =>
                                            <button onClick={() => getdata(item)} key={index}>
                                                {item}
                                            </button>
                                        )}
                                    </>

                                }


                                {/*next page with tick*/}
                                {

                                    data.paginator.nextPage > data.paginator.currentPage && (

                                        <button onClick={() => getdata(data.paginator.nextPage)} className='button is-transparent'>
                                            <NavigateBefore/>
                                        </button>
                                    )


                                }


                            </div>
                        }


                    </div>


                </section>
            </main>


        </>
    )
}
export default Courses;