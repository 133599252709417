import axios from 'axios'

export const url = "https://api.bilitim.net/api/v1"
export const baseurl = "https://api.bilitim.net"






export const getSansinfobyID = (concertID) => {
    let formdata = new FormData()
    formdata.append('concert_id', concertID)
    return axios.post(`${url}/sans`, formdata)

}









// ---------------------------- Safiran---------------------------
export const getSplash = ( ) => {
    return axios.get(`${url}/splash`)
}

export const getBlogs = (config ) => {
    return axios.get(`${url}/blogs`,config)
}
export const getBlog = (config ) => {
    return axios.get(`${url}/blog`,config)
}








