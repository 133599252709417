import React, {useEffect, useRef, useState} from 'react';

import { MapContainer, TileLayer,Popup,Marker } from 'react-leaflet'
import {getSplash} from "../../services/services";




const position = [37.5244, 45.0552];

const ContactMap = () => {
    const [dataNeeded,setData] = useState(false)

    const getdata = async ()=>{
        const res = await getSplash()
        if (res){
            const {info} = res.data
            setData(info)
        }
    }

    useEffect(() => {
        getdata().then()



    }, []);
    const mapRef = useRef();

    let data

    if (dataNeeded!==false) {
        data =[Number(dataNeeded.lat) , Number(dataNeeded.lng)]


    } else {
        data = [37.5244, 49.0552];
    }


  return (
      <>

          <div className='column is-12 my-6 p-5 ' style={{height:'24rem'}}>




          <MapContainer ref={mapRef} className='shadowtwo' center={position} zoom={11.6} scrollWheelZoom={false} style={{height:'100%' , borderRadius:'0.9rem'}}>
              <TileLayer

                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={data} >
                  <Popup className='pinar'>
                      {data}
                  </Popup>
              </Marker>

          </MapContainer>
          </div>




      </>


  )
}
export default ContactMap;