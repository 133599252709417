
import {motion} from 'framer-motion'
import {baseurl, getSplash} from '../../services/services'



import {EventCard} from '../index'
import {LinearProgress} from "@mui/material";

import {useState,useEffect} from 'react'
import {Link} from 'react-router-dom'



const Midbox = () => {




    const [dataNeeded, setData]=useState(false)
    const getdata = async ()=>{
        const res = await getSplash()
        if (res){

            setData(res.data.safiran_conference)
        } else {
            alert('مشکل در ارتباط با سرور')
        }
    }

    useEffect(() => {
        getdata().then()

    }, []);







    let content
    if (dataNeeded!==false) {



        if (dataNeeded.length > 0) {

            content = dataNeeded.map((item) => (


                <EventCard key={item.id} image={`${baseurl}/${item.concerts.img}`} id={item.concerts.id}
                             singer={item.concerts.title}
                             time={item.concerts.date_text}
                             location={item.locations_view.name} statusCode={item.concerts.active}
                             ts={item.concerts.date} remain={item.concerts.remain} city={item.cities.name}/>

            ))
        } else {
            content = <div className='column is-12'>
                <p className='yekan has-text-centered is-size-4-desktop'>
                    در شهر انتخاب شده برنامه ای جهت نمایش وجود ندارد.
                </p>
            </div>
        }


    } else {
        content = <div className='column is-12'>
            <LinearProgress variant='indeterminate' color='secondary' sx={{
                '&.MuiLinearProgress-root':{
                    backgroundColor:'#00A651'
                }

            }}/>
        </div>
    }

    return (
        <>
            <section>


                <div className='columns mx-0  is-multiline navpadend150 navpadstart150 my-6 '>
                    <div className='column is-12 is-relative'>
                        <img className='title-img' src={'/icons/Ellipse 3.png'} width={40} alt="icon" />

                        <h1 className='is-size-4-mobile is-size-4-desktop kook has-text-weight-bold mb-6 '>
                            همایش ها
                        </h1>

                        <div className='columns'>
                            <div className='column  is-12 '>
                                <motion.div className='columns is-flex is-multiline' initial='hidden' animate='visible'
                                            variants={{
                                                visible: {
                                                    opacity: 1,
                                                    scale: 1,
                                                    transition: {
                                                        delayChildren: 0.2,
                                                        staggerChildren: 0.3,
                                                        duration: 0.963

                                                    },
                                                },
                                                hidden: {
                                                    opacity: 1, scale: 0

                                                },
                                            }}>


                                    {content}


                                </motion.div>


                            </div>
                        </div>


                    </div>


                    <div className='column is-12 has-text-centered'>

                        <Link to={'/courses'}>
                            <button

                                className='button is-align-self-flex-end px-6 mx-auto  clreight kook has-text-white noborder  has-text-weight-bold '
                                style={{borderRadius: '0.6rem'}}>
                                مشاهده بیشتر
                            </button>
                        </Link>



                    </div>


                </div>
            </section>

        </>
    )
}
export default Midbox