

import {getSplash, url} from "../../services/services";
import {LinearProgress} from "@mui/material";
import axios from "axios";

import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup';

import {useEffect, useState} from "react";


const ContactBoxOne = () => {


    const [dataNeeded,setData] = useState(false)

    const getdata = async ()=>{
        const res = await getSplash()
        if (res){
            const {faq} = res.data
            setData(faq)
        }
    }

    useEffect(() => {
        getdata().then()



    }, []);
    const [sent,setSent] = useState()


    let phone
    let address

    if (dataNeeded!==false) {

        phone = dataNeeded.phone
        address = dataNeeded.address

    } else {
        address = <div className='column is-12'>
            <LinearProgress variant='indeterminate' color='info'/>
        </div>;
        phone = <div className='column is-12'>
            <LinearProgress variant='indeterminate' color='info'/>
        </div>

    }


    const handlesubmit = async (e) => {


        const form = new FormData();
        form.append("name", e.name);
        form.append("phone", e.phone);
        form.append("text", e.text);


        const response = await axios.post(`${url}/contact`, form)

        if (response.data.code === 1) {


            setSent(true)


        } else {
            alert(response.data.error)
        }
    }


    return (
        <>
            <div className='column  is-12 contact__area'>
                <div className='columns is-multiline m-0'>
                    <div className='column is-12-desktop is-12-mobile is-12-tablet borderrad1 shadowtwo clrthree  py-6 px-6'>


                        <h2 className='is-size-6-mobile is-size-5-desktop has-text-white mt-1 has-text-centered pb-5'>
                            فرم تماس با ما
                        </h2>


                        <div className='columns m-0 p-3 is-multiline ' style={{height: '100%'}}>

                            <Formik initialValues={{
                                name: '',
                                phone: '',
                                text: ''
                            }} onSubmit={(values) => handlesubmit(values)}
                                    validationSchema={Yup.object().shape({
                                        name: Yup.string()
                                            .max(50, 'باید کمتر از 50 کاراکتر باشد')
                                            .required('*ضروری'),
                                        phone: Yup.string()
                                            .matches(/^[0-9]+$/, 'فقط عدد')
                                            .length(11, 'شماره درست وارد نشده است')
                                            .required('*ضروری'),
                                        text: Yup.string()
                                            .max(400, 'باید کمتر از 400 کاراکتر باشد')
                                            .required('*ضروری'),
                                    })}


                            >
                                {({errors, touched}) => (

                                    <Form className='wdith100 columns m-0 is-multiline '>


                                        <div className='column p-1 is-6'>
                                            <Field
                                                className='wdith100 px-4  kook clrfour noborder borderrad1 is-size-6 has-text-weight-bold'
                                                id="name" name="name"
                                                placeholder=" نام و نام خانوادگی" style={{height: '3rem'}}/>


                                            <ErrorMessage component='span' className='kook has-text-white'
                                                          name='name'/>


                                        </div>


                                        <div className='column p-1 is-6'>

                                            <Field
                                                className='wdith100 px-4  has-text-right clrfour noborder borderrad1 is-size-6 has-text-weight-bold '
                                                type='tel' id="phone" name="phone"
                                                placeholder="شماره تماس" style={{height: '3rem'}}/>
                                            <ErrorMessage component='span' className='has-text-white kook'
                                                          name='phone'/>


                                        </div>


                                        <div className='column p-1 is-12'>
                                            <Field
                                                id="text"
                                                name="text"
                                                placeholder="متن پیام "
                                                type="text"
                                                as='textarea'
                                                rows='10'
                                                className='wdith100 p-4 has-text-black  clrfour noborder borderrad1 is-size-5 has-text-weight-bold'
                                            />
                                            <ErrorMessage component='span' className='has-text-white'
                                                          name='text'/>


                                        </div>


                                        <div className='mb-3 has-text-centered ' style={{width: '100%'}} >

                                            {

                                                sent ? <p className='has-text-white clrseven kook is-size-4 has-text-centered' > پیام شما دریافت شد !</p> : <button
                                                    className='button clrone  borderrad1 mx-auto mt-1 has-text-weight-bold has-text-white p-5'
                                                    type="submit" style={{backgroundColor: '#414042'}}>ارسال پیغام
                                                </button>


                                            }

                                        </div>


                                    </Form>
                                )}

                            </Formik>


                        </div>


                    </div>


                </div>


            </div>


        </>


    )
}
export default ContactBoxOne;