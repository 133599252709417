import {BuyOneBoxOne, TicketBuyRules, TicketTimecard} from '../components'
import {useParams} from 'react-router-dom'

import {LinearProgress} from '@mui/material'
import {getSansinfobyID, baseurl} from '../services/services'


import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'


const BuystepOne = () => {

    //--------------------------- fetching sans with concert id and populatin sans component
    let {concertID} = useParams()
    const [parham, setparham] = useState(false)
    const [readlaw, setreadlaw] = useState(false)

    useEffect(() => {

        const dataneeded = async () => {
            const resp = await getSansinfobyID(concertID)
            if (resp) {
                setparham(resp.data)


            }
        }

        dataneeded();

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });


    }, []);


    let cards

    if (parham !== false) {


        cards = parham.sans.map((item) => (
            <TicketTimecard key={item.id} times={item} law={readlaw}/>
        ))

    } else {
        cards = <div className='column is-12'>
            <LinearProgress variant='indeterminate' color='info'/>
        </div>
    }


// -------------------getting concerts data and finding the one with params received----------


    let content
    if (parham !== false) {

        let concerttimes = parham.sans.map((sans) => (
            `${new Date(sans.time_stamp * 1000).getHours()} `
        ))


        content = (
            <BuyOneBoxOne singer={parham.concert.title} place={parham.location.name}
                          date={parham.concert.date_text}
                          image={`${baseurl}/${parham.concert.img}`}
                          gps={[parham.location.lat, parham.location.lng]} time={concerttimes}/>
        )


    } else {
        content = <div className='column is-12'>
            <LinearProgress variant='indeterminate' color='info'/>
        </div>
    }


    return (

        <>
            <main>
                <section className="navpadend150 navpadstart150  mt-6 kook">

                    {content}
                </section>




                <div className='columns navpadend150 navpadstart150 my-6'>
                    <div className='column is-12 has-text-centered '>

                        <a href={`https://bilitim.net/schedule/${concertID}`}>
                        <button

                            className='button is-align-self-flex-end px-6 mx-auto  clreight kook has-text-white noborder  has-text-weight-bold wdith100 kook '
                            style={{borderRadius: '0.6rem'}}>
                            مشاهده و خرید
                        </button>


                        </a>
                        

                    </div>
                </div>
            </main>


        </>
    )
}

export default BuystepOne;