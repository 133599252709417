import Mainlayout from "../Layouts/Mainlayout";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import Landing from '../pages/Landing'

import {
    createBrowserRouter

} from "react-router-dom";

import { BuystepOne, Errorpage, Contact, Results, Blog, Courses,Faq,SingleBlog} from '../components'


export const router = createBrowserRouter([
    {
        path: '/',
        element: <Mainlayout/>,
        errorElement: <Errorpage/>,
        children: [
            {
                path: '/',
                element: <Landing/>


            },

            {
                path: '/schedule/:concertID',
                element: <BuystepOne/>

            },


            {
                path: '/contact/',
                element: <Contact/>

            },


            {
                path: '/faq/',
                element: <Faq/>

            },
            {
                path: '/Results/:QParams',
                element: <Results/>

            }
            ,
            {
                path: '/Blog/',
                element: <Blog/>

            }
            ,
            {
                path: '/Blogs/:blogID',
                element: <SingleBlog/>

            }

            ,
            {
                path: '/courses/',
                element: <Courses/>

            }


        ],


    },

])