import React, {useEffect, useState} from "react";
import {baseurl, getBlogs} from "../services/services";

import {BlogCard} from "../components";
import {LinearProgress} from "@mui/material";
import {NavigateBefore, NavigateNext} from "@mui/icons-material";


const Blog = () => {


    const [data, setData]=useState(false)
    const [search, setSearch] = useState('')
    const getdata = async (page)=>{
        const options = {


            params: {page: page, take: 6, search: search}
        };
        const res = await getBlogs(options)
        if (res){

            setData(res.data)
        }
    }

    useEffect(() => {
        getdata().then()
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        getdata().then()
    }, [search]);


    let content
    if (data!==false) {



        if (data.items.length > 0) {

            content = data.items.map((item) => (



                    <BlogCard key={item.id} image={`${baseurl}/${item.img}`} id={item.id}

                                   time={item.date_text}
                                   title={item.title}
                                   text={item.txt}

                    />







            ))
        } else {
            content = <div className='column is-12'>
                <p className='yekan has-text-centered is-size-4-desktop'>
                    چیزی جهت نمایش وجود ندارد.
                </p>
            </div>
        }


    } else {
        content = <div className='column is-12'>
            <LinearProgress variant='indeterminate' color='secondary' sx={{
                '&.MuiLinearProgress-root':{
                    backgroundColor:'#00A651'
                }

            }}/>
        </div>
    }


  return(
      <>
          <main>
              <section>
                  <div className="container blog__area">
              <span className="blog__top">
                <img src="/icons/Ellipse 3.png" className='title-img'/>
                <h1 className=" has-text-weight-bold has-text-black" style={{position: 'relative', right: 10, top: 5}}>بلاگ</h1>
              </span>
                      <h2 className=" has-text-weight-bold has-text-black is-size-3-desktop">دوره ها و همایش های </h2>
                      <div className="is-flex">
                          <a href="#" className=" mx-2 has-text-weight-bold" style={{color: '#A6A400'}}><p>صفحه اصلی</p></a>
                          /
                          <a href="#" className="has-text-black mx-2 has-text-weight-bold" ><p>بلاگ</p></a>
                      </div>
                      <div className="columns is-multiline ">

                          <div className='column is-12 my-3'>


                              <div className="field has-addons   ">
                                  <div className="control wdith100">
                                      <input className="input kook"
                                             onChange={(event) => setTimeout(() => setSearch(event.target.value), 1600)}
                                             type="text" placeholder="نام سالن، خواننده، شهر یا کنسرت"/>
                                  </div>
                                  <div className="control">
                                      <a className="button borderrad1  has-text-weight-bold  " disabled>
                                          یافت شده :





                                          {data && data.paginator.total}




                                      </a>
                                  </div>
                              </div>
                          </div>


                          {content}




                      </div>

                      {data &&
                          <div className='morecard wdith100'>


                              {/*before page with tick*/}
                              {
                                  data.paginator.beforePage < data.paginator.currentPage && (

                                      <button onClick={() => {
                                          setData(false);
                                          getdata(data.paginator.beforePage);
                                          window.scrollTo({
                                              top: 0,
                                              behavior: 'smooth'
                                          });
                                      }} className='button'>
                                          <NavigateNext/>
                                      </button>
                                  )


                              }


                              {/*map beforePages*/}
                              {data.paginator.beforePages.length > 0 &&

                                  <>
                                      {data.paginator.beforePages.map((item, index) =>
                                          <button onClick={() => {
                                              setData(false);
                                              getdata(item);
                                              window.scrollTo({
                                                  top: 0,
                                                  behavior: 'smooth'
                                              });
                                          }} key={index}>
                                              {item}
                                          </button>
                                      )}
                                  </>

                              }


                              {/*active*/}

                              {
                                  data.paginator.total > 0 &&
                                  <button className=' has-text-white'>{data.paginator.currentPage}</button>


                              }


                              {/*map nextPages*/}
                              {data.paginator.nextPages.length > 0 &&
                                  <>
                                      {data.paginator.nextPages.map((item, index) =>
                                          <button onClick={() => {
                                              setData(false);
                                              getdata(item);
                                              window.scrollTo({
                                                  top: 0,
                                                  behavior: 'smooth'
                                              });
                                          }} key={index}>
                                              {item}
                                          </button>
                                      )}
                                  </>

                              }


                              {/*next page with tick*/}
                              {

                                  data.paginator.nextPage > data.paginator.currentPage && (

                                      <button onClick={() => getdata(data.paginator.nextPage)} className='button is-transparent'>
                                          <NavigateBefore/>
                                      </button>
                                  )


                              }


                          </div>
                      }

                  </div>
              </section>
          </main>




      </>
  )
}
export default Blog;