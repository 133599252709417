import {useState} from "react";
import {
    Box,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    Typography,
    IconButton
} from "@mui/material";
import {

    Telegram,
    WhatsApp,
    Instagram,
    Mail,
      LinkedIn
} from '@mui/icons-material'
import {Menu} from '@mui/icons-material'
import {Link} from 'react-router-dom'




import {useNavigate} from 'react-router-dom'


const Navbar = (props) => {

    // using this to make the active page noticeable in navbar
    const [activePage, setActivePage] = useState(0)
    const handleActivePage = (page) => {
        setActivePage(page)
    }


    const nav = useNavigate()

    const drawerWidth = 240;
    const {window} = props;
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setTimeout(() => setMobileOpen((prevState) => !prevState), 300)

    };





    const drawer = (
        <Box sx={{textAlign: 'center'}}>
            <Typography onClick={() => {
                nav('/');
                handleActivePage(0)
            }} className='kook' variant="h6" sx={{my: 2}}>
              سفیران نوآوری و اندیشه
            </Typography>

            <Divider sx={{my: 1}}/>


            <List sx={{
                '& .Mui-selected': {
                    border: '0.15rem dashed #00A651',
                    borderRadius: '0.5rem',
                    backgroundColor: '#ffffff',
                    mx: 1,
                    justifyContent: 'start',
                    pr: '2rem'
                }
            }}>

                <ListItem onClick={handleDrawerToggle} disablePadding>
                    <ListItemButton selected={activePage === 0} onClick={() => {
                        nav('/');
                        handleActivePage(0)
                    }}
                                    sx={{textAlign: 'center', height: '3rem'}}>
                        <Typography className='kook' variant="h6" component='a' sx={{my: 2 , color:'inherit'}}>
                            صفحه اصلی
                        </Typography>
                    </ListItemButton>
                </ListItem>

                <ListItem onClick={handleDrawerToggle} disablePadding>
                    <ListItemButton selected={activePage === 1} onClick={() => {
                        nav('/courses/');
                        handleActivePage(1)
                    }}
                                    sx={{textAlign: 'center', height: '3rem'}}>
                        <Typography className='kook' variant='h6' component='a'  sx={{my: 2 , color:'inherit'}}>
                           همایش / دوره
                        </Typography>
                    </ListItemButton>
                </ListItem>

                <ListItem onClick={handleDrawerToggle} disablePadding>
                    <ListItemButton selected={activePage === 2} onClick={() => {
                        nav('/blog/');
                        handleActivePage(2)
                    }} sx={{textAlign: 'center', height: '3rem'}}>
                        <Typography className='kook' variant="h6" component='a' sx={{my: 2 , color:'inherit'}}>
                            بلاگ
                        </Typography>
                    </ListItemButton>
                </ListItem>


                <ListItem onClick={handleDrawerToggle} disablePadding>
                    <ListItemButton selected={activePage === 4} onClick={() => {
                        nav('/contact/');
                        handleActivePage(4)
                    }} sx={{textAlign: 'center', height: '3rem'}}>
                        <Typography className='kook' variant="h6" component='a' sx={{my: 2 , color:'inherit'}}>
                            تماس با ما
                        </Typography>
                    </ListItemButton>
                </ListItem>

                <ListItem onClick={handleDrawerToggle} disablePadding>
                    <ListItemButton selected={activePage === 3} onClick={() => {
                        nav('/faq/');
                        handleActivePage(3)
                    }} sx={{textAlign: 'center', height: '3rem'}}>
                        <Typography className='kook' variant="h6" component='a' sx={{my: 2 , color:'inherit'}}>
                           سوالات متداول
                        </Typography>
                    </ListItemButton>
                </ListItem>


                <a href="https://ofoghiranian.com/work-with-us/" target={'_blank'}>
                    <ListItem  disablePadding>
                        <ListItemButton  sx={{textAlign: 'center', height: '3rem'}}>
                            <Typography className='kook' variant="h6" component='a' sx={{my: 2 , color:'black'}}>
                                فرصت های شغلی
                            </Typography>
                        </ListItemButton>
                    </ListItem>

                </a>
                <a href="https://bilitim.net/login" target={'_blank'}>
                    <ListItem  disablePadding>
                        <ListItemButton  sx={{textAlign: 'center', height: '3rem'}}>
                            <Typography className='kook' variant="h6" component='a' sx={{my: 2 , color:'black'}}>
                                ورود/ثبت نام
                            </Typography>
                        </ListItemButton>
                    </ListItem>

                </a>


            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;


    return (
        <>
            <header>


                <nav className="navbar stcikynavmobile paddingtopandbuttom20 is-justify-content-center pb-0 kook"
                     role="navigation"
                     aria-label="main navigation" style={{width: '100%'}}
                >


                    {/*Responsive Drawer*/}
                    <nav>
                        <Drawer
                            container={container}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            anchor='right'
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: {xs: 'block', sm: 'block', md: 'block'},
                                '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth , backgroundColor:'rgba(255, 255, 255, 0.93)' , backdropFilter:'blur(3px)' , borderLeft:'0.3rem solid #00A651' },

                            }}
                        >

                            {drawer}
                        </Drawer>
                    </nav>

                    {/*Responsive Drawer*/}

                    {/*responsive*/}
                    <div className='is-flex is-justify-content-space-between is-align-items-center is-hidden-desktop'>


                        <button onClick={()=>nav('/courses')} className='button is-size-6 clrone has-text-white mx-3 kook' style={{borderRadius:'0.3rem'}}>
                            همایش / دوره ها
                        </button>

                        <a className='ml-auto mr-1'   onClick={() => {
                            nav('/');
                            handleActivePage(0)
                        }}

                          >
                            <img src="/icons/headlogo.png" width={30} height={30} style={{maxHeight: '30px'}}/>



                        </a>




                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ml: 1, display: {lg: 'none'}}}
                        >
                            <Menu className='is-size-3'/>
                        </IconButton>



                    </div>


                    <div className='columns is-multiline m-0 wdith100 is-hidden-touch    '>

                        <div className='column is-2 is-flex is-flex-direction-row flex-JCS-ACS '>





                            <a href={'https://bilitim.net/login'} target={'_blank'}
                               className={`navbar-item  underline is-flex is-flex-direction-column `}>
                                <img src="/icons/icon_user_12.png" alt="" />
                                ورود / ثبت نام
                            </a>
                        </div>


                        <div className='column is-8 is-flex is-flex-direction-row flex-JCS-ACS'>
                            <Link to={'/'} className={`navbar-item  underline `}>


                                صفحه اصلی
                            </Link>
                            <Link to={'/courses/'} className={`navbar-item  underline `}>
                               همایش ها / دوره ها
                            </Link>


                            <Link to={'/blog/'}  className={`navbar-item  underline`} >
                                بلاگ
                            </Link>

                            <Link to={'/'} className={`navbar-item is-hidden-mobile underline px-6 `}
                                  style={{alignItems: 'center'}}>
                                <img src="/icons/headlogo.png" width={60} height={60} style={{maxHeight: '60px'}}/>




                            </Link>


                            <Link to={'/faq/'}  className={`navbar-item  underline `}>
                                سوالات متداول
                            </Link>

                            <Link to={'/contact/'} className={`navbar-item  underline `}>
                                ارتباط با ما
                            </Link>


                            <a href={'https://ofoghiranian.com/work-with-us/'} target={'_blank'} rel={'nofollow noindex'} className={`navbar-item  underline `}>
                                فرصت های شغلی
                            </a>

                        </div>


                        <div className='column is-2 is-flex is-flex-direction-row flex-JCS-ACS'>
                            
                        <a href="#" className='mx-1 p-1 is-flex flex-JCS-ACS' style={{borderRadius: '50%' , border:'1px solid #414042'}}>
                                <WhatsApp className='clrfivetext'/>
                            </a>
                            <a href="#" className='mx-1 p-1 is-flex flex-JCS-ACS' style={{borderRadius: '50%' , border:'1px solid #414042'}}>
                                <Telegram className='clrfivetext'/>
                            </a>
                                
                            <a href="#" className='mx-1 p-1 is-flex flex-JCS-ACS' style={{borderRadius: '50%' , border:'1px solid #414042'}}>
                                <LinkedIn className='clrfivetext'/>
                            </a>
                            <a href="#" className='mx-1 p-1 is-flex flex-JCS-ACS' style={{borderRadius: '50%' , border:'1px solid #414042'}}>
                                <Mail className='clrfivetext'/>
                            </a>
                            <a href="#" className='mx-1 p-1 is-flex flex-JCS-ACS' style={{borderRadius: '50%' , border:'1px solid #414042'}}>
                                <Instagram className='clrfivetext'/>
                            </a>
                        </div>


                    </div>


                </nav>

            </header>

        </>
    )
}
export default Navbar;