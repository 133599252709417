
import {BotSlider} from '../index'
import {Link} from 'react-router-dom'

const Bottombox = () => {








    return (
        <>
            <section>
                <div className='columns mx-0 is-multiline navpadend150 navpadstart150  mb-6'>



                            <div className='column is-12 home__mid__lit'>

                                <div className='columns'>
                                    <div className='column'>
                                    <img className='title-img' src={'/icons/Ellipse 3.png'} width={40} alt="icon" />
                                <h3 className='is-size-4-mobile is-size-4 has-text-weight-bold kook '>
                                    مقالات و مجلات ما
                                </h3>
                                    </div>
                                </div>
                                <div className='column'>
                                <Link to='/blog'>
                                        <button


                                            className='button is-align-self-flex-end px-6 mx-auto  clreight kook has-text-white noborder  has-text-weight-bold '
                                            style={{borderRadius: '0.3rem' , float:"left" }}>
                                            مشاهده بیشتر
                                        </button>
                                    </Link>
                                </div>
                            </div>



                    <div className='column is-12'>
                        <BotSlider/>

                    </div>












                </div>


            </section>


        </>

    )
}
export default Bottombox