import React, {useEffect, useState} from 'react';
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';

import { Navigation, Pagination, Autoplay} from 'swiper/modules';


import {BotsliderCard} from "../../index";
import {baseurl, getSplash} from "../../../services/services";
import {LinearProgress} from "@mui/material";

const BottomSlider = () => {
    const [dataNeeded, setData]=useState(false)
    const getdata = async ()=>{
        const res = await getSplash()
        if (res){

            setData(res.data.safiran_blogs)
        }
    }

    useEffect(() => {
        getdata().then()

    }, []);



    let content
    if (dataNeeded!==false) {



        if (dataNeeded.length > 0) {

            content = dataNeeded.map((item) => (

                <SwiperSlide>

                        <BotsliderCard key={item.id} image={`${baseurl}/${item.img}`} id={item.id}

                                     time={item.date_text}
                                       title={item.title}
                                       text={item.txt}

                                     />


                </SwiperSlide>




            ))
        } else {
            content = <div className='column is-12'>
                <p className='yekan has-text-centered is-size-4-desktop'>
                    چیزی جهت نمایش وجود ندارد.
                </p>
            </div>
        }


    } else {
        content = <div className='column is-12'>
            <LinearProgress variant='indeterminate' color='info'/>
        </div>
    }






    return (
        <>

            <div style={{height:'50rem'}}>
                <Swiper
                    spaceBetween={50}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true
                    }}
                    breakpoints={
                        {
                            200: {
                                slidesPerView: 1
                            },
                            1024: {
                                slidesPerView: 3
                            }
                        }
                    }


                    speed={1618}
                    loop={true}
                    navigation={true}
                    pagination={{

                        bulletActiveClass:'bulletbg',





                    }}
                    modules={[ Navigation, Pagination, Autoplay]}
                    style={{height:'100%' , width:'100%'}}

                >



                    {content}



                </Swiper>

            </div>




        </>
    )
}
export default BottomSlider;