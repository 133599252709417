import {Accordion,AccordionSummary,Typography,AccordionDetails,} from '@mui/material'
import {ArrowDownwardRounded} from '@mui/icons-material'



const Accardion = ({q , a ,id}) => {



    return (
        <>
            <div className='column px-0 is-12'>
                <Accordion  className='borderclerone py-4'>
                    <AccordionSummary
                        expandIcon={<ArrowDownwardRounded/>}
                        aria-controls="panel1a-content"
                        id={ `accard-${id}`}
                    >
                        <Typography className='kook is-size-5-mobile is-size-4 has-text-weight-bold clronetext  '> {q}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component='article' className='kook is-size-5'>
                            {a}
                        </Typography>
                    </AccordionDetails>
                </Accordion>



            </div>














        </>

    )
}
export default Accardion;